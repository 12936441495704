import Header from '../components/header';
import Footer from '../components/footer';
import { NavLink } from 'react-router-dom';
import home_sofa from '../assets/media/home_sofa.png';
import homeImage from '../assets/media/rolfo.jpg'

function Home() {
  return (
    <div className="mainContainer">
      <Header />
      <div className='homeContainer'>
        <div className='homeContainer__about'>
          <h1>Rolfo - kasutatud mööbel Soomest</h1>
          <text>OÜ Rolfo  tegeleb  Soomest kasutatud pehme mööbli maaletoomise ja müümisega. Oleme asutatud 2010. aastal. Pakume oma klientidele meeldivat ja kiiret teenindust.
          Ostes kasutatud mööblit säästate kindlasti raha aga säästate ka loodust! Tutvu meie tootevalikuga.</text>
          <text>Anname endast parima, et meie veebilehet kuvatud tooted ning reaalne laoseis oleks alati vastavuses.</text>
          <NavLink to={{pathname: '/shop'}} className='homeContainer__buttonArea'>
            <button className='greenButton'>Vaata tooteid</button>
          </NavLink>
        </div>
              <img src={homeImage} className='homeContainer__img'/>
      </div>
      <Footer />
    </div>
  )
}

export default Home
