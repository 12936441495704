import React, { Component } from 'react';
import { Switch, Route, BrowserRouter as Router} from 'react-router-dom';
import Home from './pages/home?v1';
import Shop from './pages/shop?v1';
import Contacts from './pages/contacts?v1';

import './assets/css/style.css';

class App extends Component {
  render() {
    return (
        <Router>
          <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/shop' component={Shop} />
            <Route exact path='/contacts' component={Contacts} />
            <Route exact path='/contacts' component={Contacts} />
            <Route exact path='/Kontakt' component={Contacts} />
            <Route exact path='/voodid' component={() => <Shop cat='0' />} />
            <Route exact path='/madratsid' component={() => <Shop cat='1' />} />
            <Route exact path='/diivanid' component={() => <Shop cat='2' />} />
            <Route exact path='/diivanvoodid' component={() => <Shop cat='3' />} />
            <Route exact path='/komplektid' component={() => <Shop cat='4' />} />
            <Route exact path='/nurgadiivanid' component={() => <Shop cat='5' />} />
            <Route exact path='/nahkmoobel' component={() => <Shop cat='6' />} />
            <Route exact path='/lauadtoolid' component={() => <Shop cat='7' />} />
            <Route exact path='/kapidriiulid' component={() => <Shop cat='8' />} />
            <Route exact path='/sisustus' component={() => <Shop cat='9' />} />
          </Switch>
        </Router>
    )
  }
}

export default App;
